<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="真实姓名" name="realName">
              <a-input
                placeholder="请输入真实姓名"
                v-model:value="form.realName"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号" name="phoneNumber">
              <a-input
                placeholder="请输入手机号"
                v-model:value="form.phoneNumber"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operator">
              <a-select
                v-model:value="form.operator"
                placeholder="请选择所属运营商"
              >
                <a-select-option value="0"
                  >厦门远丞科技有限公司</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="提现状态" name="withdrawalState">
              <a-select
                v-model:value="form.withdrawalState"
                placeholder="请选择提现状态"
              >
                <a-select-option value="0">待审核</a-select-option>
                <a-select-option value="1">审核未通过</a-select-option>
                <a-select-option value="2">提现中</a-select-option>
                <a-select-option value="3">提现成功</a-select-option>
                <a-select-option value="4">提现失败</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="提现申请时间" name="withdrawalApplyTime">
              <a-range-picker
                format="YYYY-MM-DD"
                :placeholder="['开始日期', '结束日期']"
                @change="onChange"
                style="width: 100%"
                v-model:value="form.withdrawalApplyTime"
                @ok="onOk"
              /> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="提现完成时间" name="withdrawalFinishTime">
              <a-range-picker
                format="YYYY-MM-DD"
                :placeholder="['开始日期', '结束日期']"
                @change="onChange"
                style="width: 100%"
                v-model:value="form.withdrawalFinishTime"
                @ok="onOk"
              /> </a-form-item
          ></a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row> </a-form
    ></a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table
          ref="table"
          :rowKey="(record) => record.phoneNumber"
          :columns="columns"
          :datasource="data"
          @change="handleChange"
          :scroll="{ x: 'max-content' }"
        >
          <template #toolbar>
          </template>
          <template #action="{ record }">
            <a-space>
              <a @click="openDetail(record)">通过</a>
              <a @click="openEdit(record)">驳回</a>
              <a-divider type="vertical" />
              <a-popconfirm title="确定要删除此项目吗？">
                <a class="ud-text-danger">删除</a>
              </a-popconfirm>
            </a-space>
          </template>
          <template #customTitle>
            <div>
              所属运营商
              <i
                class="iconfont icon-bukejian"
                style="cursor: pointer"
                v-if="!isShowOperator"
                @click="isShowOperator = true"
              ></i>
              <i
                class="iconfont icon-kejian"
                style="cursor: pointer"
                v-else
                @click="isShowOperator = false"
              ></i>
            </div>
          </template>
          <template #operatorCustom="{ record }">
            <div v-if="!isShowOperator">
              {{
                record.operator.substring(0, 1) +
                new Array(record.operator.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.operator }}
            </div>
          </template>
        </ud-pro-table>
      </div></a-card
    >

    <!-- <operation-detail
      v-model:visible="showDetail"
      :data="current || {}"
    ></operation-detail>
    <operation-edit v-model:visible="showEdit" :data="current" @done="reload" /> -->
  </div>
</template>

<script>
// import operationDetail from './operation-detail.vue'
// import operationEdit from './operation-edit.vue'
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    // DownOutlined,
    // ExclamationCircleOutlined
    // operationDetail,
    // operationEdit
  },
  data() {
    return {
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      actionForm: { endTime: '' },
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '手机号',
          // width: 170,
          // align: 'center',
          dataIndex: 'phoneNumber'
        },
        {
          title: '真实姓名',
          // width: 85,
          // align: 'center',
          dataIndex: 'realName'
        },
        {
          title: '提现状态',
          // width: 175,
          // align: 'center',
          dataIndex: 'withdrawalState'
        },
        {
          title: '提现金额(元)',
          // width: 175,
          // align: 'center',
          dataIndex: 'withdrawalMoney'
        },
        {
          title: '提现成功金额(元)',
          // width: 175,
          // align: 'center',
          dataIndex: 'withdrawalSuccessMoney'
        },
        {
          title: '提现申请时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'withdrawalApplyTime'
        },
        {
          title: '提现审核时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'withdrawalCheckTime'
        },
        {
          title: '提现完成时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'withdrawalFinishTime'
        },
        {
          // width: 175,
          // align: 'center',
          dataIndex: 'operator',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '操作',
          width: 200,
          dataIndex: 'action',
          align: 'center',
          slots: {
            customRender: 'action'
          }
        }
      ],
      data: [
        {
          phoneNumber: '18370082684',
          realName: '张俊杰',
          withdrawalState: '待审核',
          withdrawalMoney: '320.0',
          withdrawalSuccessMoney: '0',
          withdrawalApplyTime: '2022-01-11 09:45:36',
          withdrawalCheckTime: '2022-01-11 09:45:36',
          withdrawalFinishTime: '2022-01-11 09:45:36',
          operator: '厦门远丞科技有限公司'
        },
        {
          phoneNumber: '18370082685',
          realName: '张俊杰',
          withdrawalState: '待审核',
          withdrawalMoney: '320.0',
          withdrawalSuccessMoney: '0',
          withdrawalApplyTime: '2022-01-11 09:45:36',
          withdrawalCheckTime: '2022-01-11 09:45:36',
          withdrawalFinishTime: '2022-01-11 09:45:36',
          operator: '厦门远丞科技有限公司'
        }
      ]
    }
  },
  methods: {
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
